import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'

import {
  checkMinLengthIsValid,
  checkMaxLengthIsValid,
} from '../services/editableregion.service'

import AdminCard from './adminCard'

const useStyles = makeStyles({
  formControl: { marginBottom: '20px', width: 200 },
})

const EditableRegionFormValidation = ({
  inputTypeId,
  minLength,
  minLengthChanged,
  maxLength,
  maxLengthChanged,
}) => {
  const classes = useStyles()

  const [minLengthIsValid, setMinLengthIsValid] = useState(false)
  const [maxLengthIsValid, setMaxLengthIsValid] = useState(false)

  useEffect(
    () =>
      setMinLengthIsValid(
        checkMinLengthIsValid(inputTypeId, minLength, maxLength)
      ),
    [inputTypeId, minLength, maxLength]
  )
  useEffect(
    () =>
      setMaxLengthIsValid(
        checkMaxLengthIsValid(inputTypeId, minLength, maxLength)
      ),
    [inputTypeId, minLength, maxLength]
  )

  const [minLengthValidationMessage, setMinLengthValidationMessage] = useState(
    ''
  )

  useEffect(() => {
    setMinLengthValidationMessage(
      minLengthIsValid
        ? 'Valid Entry'
        : 'Must be greater or equal to 1 and less than Max Length'
    )
  }, [minLengthIsValid])

  const [maxLengthValidationMessage, setMaxLengthValidationMessage] = useState(
    ''
  )

  useEffect(() => {
    setMaxLengthValidationMessage(
      maxLengthIsValid
        ? 'Valid Entry'
        : 'Must be greather than Min Length and less than or equal to 100'
    )
  }, [maxLengthIsValid])

  return (
    <>
      <AdminCard title={'Character Limit'}>
        <div>
          <FormControl
            className={classes.formControl}
            error={!minLengthIsValid}>
            <TextField
              type='number'
              label='Min Length'
              value={minLength}
              onChange={e => minLengthChanged(e.target.value)}
            />
            <FormHelperText>{minLengthValidationMessage}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <FormControl
            className={classes.formControl}
            error={!maxLengthIsValid}>
            <TextField
              type='number'
              label='Max Length'
              value={maxLength}
              onChange={e => maxLengthChanged(e.target.value)}
            />
            <FormHelperText>{maxLengthValidationMessage}</FormHelperText>
          </FormControl>
        </div>
      </AdminCard>
    </>
  )
}

export default EditableRegionFormValidation
