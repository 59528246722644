import React, { useEffect } from 'react'
import { signOutRedirectCallback } from '../services/auth.service'
import { useHistory } from 'react-router-dom'
import LoadingPage from '../components/loadingPage'

function SignOutCallback() {
  const history = useHistory()

  useEffect(() => {
    signOutRedirectCallback().then(() => {
      history.push('/')
    })
  }, [history])

  return <LoadingPage text='Logging out' />
}

export default SignOutCallback
