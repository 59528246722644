import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router'
import { ReactComponent as AuthImage } from '../images/authentication.svg'

const useStyles = makeStyles(theme => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '32px',
  },
  imageContainer: {
    maxWidth: '600px',
    marginBottom: '32px',
  },
  image: {
    height: '100%',
    width: '100%',
  },
}))

const NoPermissions = () => {
  const classes = useStyles()
  const location = useLocation()

  return (
    <div className={classes.pageContainer}>
      <div className={classes.imageContainer}>
        <AuthImage className={classes.image} />
      </div>
      <p>
        You do not have required permissions to view
        {location.state && location.state.from ? (
          <strong> {location.state.from}</strong>
        ) : (
          ' this page'
        )}
      </p>
    </div>
  )
}

export default NoPermissions
