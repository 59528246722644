import React, { useEffect, useState } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Container, FormControl, Select, MenuItem } from '@material-ui/core'

import MainLayout from '../layouts/mainLayout'
import AdminPageHeader from '../components/adminPageHeader'

import DocumentTemplateList from './subpages/admin/documentTemplateList'
import DocumentTemplateAdd from './subpages/admin/documentTemplateAdd'
import DocumentTemplateEdit from './subpages/admin/documentTemplateEdit'

import EditableRegionList from './subpages/admin/editableRegionList'
import EditableRegionAdd from './subpages/admin/editableRegionAdd'
import EditableRegionEdit from './subpages/admin/editableRegionEdit'

import EditableRegionGroupList from './subpages/admin/editableRegionGroupList'
import EditableRegionGroupEdit from './subpages/admin/editableRegionGroupEdit'

import DocumentBrandingList from './subpages/admin/documentBrandingList'
import DocumentBrandingAdd from './subpages/admin/documentBrandingAdd'
import DocumentBrandingEdit from './subpages/admin/documentBrandingEdit'

import { SubNavButton } from '../components/navigation'
import { useService } from '../contexts/serviceContext'
import adminService from '../services/admin.service'

const AdminWelcome = () => {
  return (
    <>
      <AdminPageHeader title={'Admin'} />

      <p>
        Welcome to the document service admin tool, use the navbar above to
        select an area to edit.
      </p>
    </>
  )
}

const Admin = () => {
  const history = useHistory()
  const location = useLocation()

  const { serviceId, setServiceId } = useService()
  const [services, setServices] = useState([])

  useEffect(() => {
    adminService.getAdminServices().then(res => setServices(res))
  }, [])

  const LeftSubNav = () => {
    return (
      serviceId !== 0 && (
        <>
          <SubNavButton
            handleClick={() => history.push('/admin/template')}
            label='Templates'
          />
          <SubNavButton
            handleClick={() => history.push('/admin/editable')}
            label='Editables'
          />
          {/* <SubNavButton
            handleClick={() => history.push('/admin/editablegroup')}
            label='Editable Groups'
          /> */}
          <SubNavButton
            handleClick={() => history.push('/admin/branding')}
            label='Branding'
          />
        </>
      )
    )
  }

  const RightSubNav = () => {
    return (
      <>
        <FormControl variant='standard'>
          <Select
            value={serviceId}
            onChange={e => setServiceId(e.target.value)}
            displayEmpty>
            <MenuItem value={0}>
              <em>None</em>
            </MenuItem>
            {services.map(service => (
              <MenuItem
                key={service.processEngineServiceId}
                value={service.processEngineServiceId}>
                {service.serviceName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    )
  }

  return (
    <MainLayout leftSubNav={LeftSubNav} rightSubNav={RightSubNav}>
      <Container>
        {location.pathname === '/admin' || location.pathname === '/admin/' ? (
          <AdminWelcome />
        ) : null}
        <Switch>
          <Route
            exact
            path='/admin/branding'
            component={DocumentBrandingList}
          />
          <Route
            exact
            path='/admin/branding/add'
            component={DocumentBrandingAdd}
          />
          <Route path='/admin/branding/:id' component={DocumentBrandingEdit} />

          <Route
            exact
            path='/admin/template'
            component={DocumentTemplateList}
          />
          <Route
            exact
            path='/admin/template/add'
            component={DocumentTemplateAdd}
          />
          <Route path='/admin/template/:id' component={DocumentTemplateEdit} />

          <Route exact path='/admin/editable' component={EditableRegionList} />
          <Route
            exact
            path='/admin/editable/add'
            component={EditableRegionAdd}
          />
          <Route path='/admin/editable/:id' component={EditableRegionEdit} />

          <Route exact path='/admin/editablegroup' component={EditableRegionGroupList} />
          
          <Route exact path='/admin/editablegroup/:id' component={EditableRegionGroupEdit} />
        </Switch>
      </Container>
    </MainLayout>
  )
}

export default Admin
