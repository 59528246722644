import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}))

const ActionBar = ({ leftContent, rightContent }) => {
  const classes = useStyles()

  const ActionBarSection = content => (
    <Grid item className={classes.buttonContainer}>
      {content.filter(item => item.show).map(item => ActionBarButton(item))}
    </Grid>
  )

  const ActionBarButton = item => {
    const { label, show, isPrimaryAction, ...rest } = item
    return (
      <Button
        key={label}
        {...rest}
        variant={isPrimaryAction ? 'contained' : 'outlined'}
        color={isPrimaryAction ? 'secondary' : 'default'}>
        {label}
      </Button>
    )
  }

  return (
    <Grid container direction='row' justify='space-between'>
      {leftContent && ActionBarSection(leftContent)}
      {rightContent && ActionBarSection(rightContent)}
    </Grid>
  )
}

ActionBar.propTypes = {
  leftContent: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      show: PropTypes.bool.isRequired,
      isPrimaryAction: PropTypes.bool.isRequired,
      disabled: PropTypes.bool,
      type: PropTypes.oneOf(['submit', undefined]),
      onClick: PropTypes.func,
    })
  ),
  rightContent: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      show: PropTypes.bool.isRequired,
      isPrimaryAction: PropTypes.bool.isRequired,
      disabled: PropTypes.bool,
      type: PropTypes.oneOf(['submit', undefined]),
      onClick: PropTypes.func,
    })
  ),
}

export default ActionBar
