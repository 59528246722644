import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import thunk from 'redux-thunk'

// https://github.com/reduxjs/redux-toolkit/issues/476
import { enableES5 } from 'immer'
enableES5()

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: true,
})

export default store
