import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import AdminCard from './adminCard'
import { useService } from '../contexts/serviceContext'

const useStyles = makeStyles({
  formControl: { marginBottom: '20px', width: 200 },
  submitControl: { marginTop: '20px' },
  cardComponent: { marginBottom: '20px' },
  textbox: { minHeight: 260 },
})

//TOOLS TO SHOW IN THE TELERIK EDITOR
const editorTools = [
  'bold',
  'italic',
  'underline',
  'justifyLeft',
  'justifyCenter',
  'justifyRight',
  'justifyFull',
  'insertUnorderedList',
  'insertOrderedList',
  'indent',
  'outdent',
  'insertImage',
  'tableWizard',
  'createTable',
  'addRowAbove',
  'addRowBelow',
  'addColumnLeft',
  'addColumnRight',
  'deleteRow',
  'deleteColumn',
  'mergeCellsHorizontally',
  'mergeCellsVertically',
  'splitCellHorizontally',
  'splitCellVertically',
  'viewHtml',
  'formatting',
  'cleanFormatting',
  'copyFormat',
  'applyFormat',
  // 'fontName',
  // 'fontSize',
  'foreColor',
  'backColor',
]

const EditableRegionFormOptionsLarge = ({ id, options, optionsChanged }) => {
  const classes = useStyles()

  const [isAddingOption, setIsAddingOption] = useState(false)
  const [isEditingOption, setIsEditingOption] = useState(false)

  const [optionId, setOptionId] = useState(0)
  const [optionDescription, setOptionDescription] = useState('')
  const [optionDescriptionIsValid, setOptionDescriptionIsValid] = useState(
    false
  )
  const [
    optionDescriptionValidationMessage,
    setOptionDescriptionValidationMessage,
  ] = useState('')
  useEffect(() => setOptionDescriptionIsValid(optionDescription.length > 2), [
    optionDescription,
  ])
  useEffect(
    () =>
      setOptionDescriptionValidationMessage(
        optionDescriptionIsValid ? 'Valid Input' : 'Input at least 3 characters'
      ),
    [optionDescriptionIsValid]
  )

  const editorRef = useRef()
  const editorWrapperRef = useRef()
  const [editorState, setEditorState] = useState('')
  const [editorIsValid, setEditorIsValid] = useState(false)
  const [editorValidationMessage, setEditorValidationMessage] = useState('')
  useEffect(() => setEditorIsValid(editorState.length > 10), [editorState])
  useEffect(
    () =>
      setEditorValidationMessage(
        editorIsValid ? 'Valid Input' : 'Enter content'
      ),
    [editorIsValid]
  )

  const [newOptionIsValid, setOptionIsValid] = useState(false)
  useEffect(() => setOptionIsValid(optionDescriptionIsValid && editorIsValid), [
    optionDescriptionIsValid,
    editorIsValid,
  ])

  const [optionsAreValid, setOptionsAreValid] = useState(false)
  useEffect(
    () => setOptionsAreValid(options.filter(x => !x.isArchived).length > 1),
    [options]
  )

  const cancelNewOption = () => {
    setOptionDescription('')
    setEditorState('')
    setIsAddingOption(false)
    setIsEditingOption(false)
    setOptionId(0)
    setExpanded(false)
  }

  const submitOption = () => {
    if (isAddingOption) {
      optionsChanged([
        ...options,
        {
          id: 0,
          editableRegionId: id,
          value: editorState,
          description: optionDescription,
          isArchived: false,
        },
      ])
    } else if (isEditingOption) {
      var o = [...options]
      var match = o.find(x => x.id === optionId)
      if (match) {
        match.description = optionDescription
        match.value = editorState
      }
      optionsChanged(o)
    }
    cancelNewOption()
  }

  const [expanded, setExpanded] = useState(false)
  const handleExpand = panel => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false)

  const handleEditOption = option => {
    setOptionId(option.id)
    setOptionDescription(option.description)
    setEditorState(option.value)
    setIsAddingOption(false)
    setIsEditingOption(true)
  }

  const onEditorChange = () => EditorChanged()

  const EditorChanged = () => {
    let $ = window.jQuery
    const editor = $(editorRef.current).data('kendoEditor')
    if (editor) setEditorState(editor.value())
  }

  const { serviceConfig } = useService()

  useEffect(() => {
    if (isEditingOption) {
      let $ = window.jQuery
      $(editorRef.current).kendoEditor({
        stylesheets: [
          `/css/editor/font-family-${serviceConfig.fontFamily}.css`,
          `/css/editor/font-size-${serviceConfig.fontSize}.css`,
        ],
        tools: editorTools,
        change: onEditorChange,
        select: onEditorChange,
        execute: onEditorChange,
        paste: onEditorChange,
      })
      var editor = $(editorRef.current).data('kendoEditor')
      editor.value(editorState)
      editorWrapperRef.current.style.display = 'block'
    }
  }, [isEditingOption])

  useEffect(() => {
    if (isAddingOption) {
      let $ = window.jQuery
      $(editorRef.current).kendoEditor({
        stylesheets: [
          `/css/editor/font-family-${serviceConfig.fontFamily}.css`,
          `/css/editor/font-size-${serviceConfig.fontFamily}.css`,
        ],
        tools: editorTools,
        change: onEditorChange,
        select: onEditorChange,
        execute: onEditorChange,
        paste: onEditorChange,
      })
      var editor = $(editorRef.current).data('kendoEditor')
      editor.value(editorState)
      editorWrapperRef.current.style.display = 'block'
    }
  }, [isAddingOption])

  const handleDeleteOption = index => {
    options[index].isArchived = !options[index].isArchived
    optionsChanged([...options])
    setExpanded(false)
  }

  return (
    <>
      {!isAddingOption && !isEditingOption && (
        <>
          <div>
            {options.length === 0 ? (
              <AdminCard title={'Large Options'}>
                <p>No options</p>
              </AdminCard>
            ) : (
              <>
                <div style={{ marginBottom: 20 }}>
                  {options
                    .sort((a, b) => a.isArchived > b.isArchived)
                    .map((option, index) => (
                      <ExpansionPanel
                        key={index}
                        expanded={expanded === `panel-${index}`}
                        onChange={handleExpand(`panel-${index}`)}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography
                            style={
                              option.isArchived
                                ? { textDecoration: 'line-through' }
                                : {}
                            }>
                            {option.description}
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Typography>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: option.value,
                              }}></span>
                          </Typography>
                        </ExpansionPanelDetails>
                        <ExpansionPanelActions>
                          {!option.isArchived && (
                            <Button
                              size='small'
                              color='primary'
                              onClick={() => handleEditOption(option)}>
                              Edit
                            </Button>
                          )}
                          {option.isArchived ? (
                            <Button
                              size='small'
                              color='primary'
                              onClick={() => handleDeleteOption(index)}>
                              Restore
                            </Button>
                          ) : (
                            <Button
                              size='small'
                              color='primary'
                              onClick={() => handleDeleteOption(index)}>
                              Delete
                            </Button>
                          )}
                        </ExpansionPanelActions>
                      </ExpansionPanel>
                    ))}
                </div>
                {!optionsAreValid && (
                  <AdminCard>
                    <FormControl error={!optionsAreValid}>
                      <FormHelperText>Add more options</FormHelperText>
                    </FormControl>
                  </AdminCard>
                )}
              </>
            )}
          </div>
          <div>
            <Button
              className={classes.submitControl}
              onClick={() => setIsAddingOption(true)}
              variant='contained'
              color='secondary'>
              Add New Option
            </Button>
          </div>
        </>
      )}
      {(isAddingOption || isEditingOption) && (
        <AdminCard title={'Option'}>
          <div>
            <FormControl
              className={classes.formControl}
              error={!optionDescriptionIsValid}>
              <TextField
                type='text'
                label='Description'
                value={optionDescription}
                onChange={e => setOptionDescription(e.target.value)}
              />
              <FormHelperText>
                {optionDescriptionValidationMessage}
              </FormHelperText>
            </FormControl>
          </div>
          <div style={{ height: 300 }}>
            <div ref={editorWrapperRef} style={{ display: 'none' }}>
              <textarea ref={editorRef}></textarea>
            </div>
            <FormHelperText error={!editorIsValid}>
              {editorValidationMessage}
            </FormHelperText>
          </div>
          <div>
            <Button
              style={{ marginRight: 10 }}
              className={classes.submitControl}
              onClick={cancelNewOption}
              variant='outlined'>
              Cancel
            </Button>
            <Button
              style={{ marginRight: 10 }}
              disabled={!newOptionIsValid}
              className={classes.submitControl}
              onClick={submitOption}
              variant='contained'
              color='secondary'>
              Save
            </Button>
          </div>
        </AdminCard>
      )}
    </>
  )
}

export default EditableRegionFormOptionsLarge
