import React, { useEffect } from 'react'
import { signInRedirectCallback } from '../services/auth.service'
import { useHistory } from 'react-router-dom'
import LoadingPage from '../components/loadingPage'
import { useDispatch } from 'react-redux'
import { storeUser } from '../actions/auth.actions'

function SignInCallback() {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    signInRedirectCallback().then(user => {
      window.history.replaceState(
        {},
        window.document.title,
        window.location.origin + window.location.pathname
      )

      history.push((user.state && user.state.postLoginRedirect) || '/')
    })
  }, [history])

  return <LoadingPage text='Signing in' />
}

export default SignInCallback
