import {
  GET_SERVICE_FAILURE,
  GET_SERVICE_REQUEST,
  GET_SERVICE_SUCCESS,
} from './types'

import serviceService from '../services/service.service'

export function getService(serviceId) {
  return dispatch => {
    dispatch(request())

    serviceService.getService(serviceId).then(
      response => {
        return dispatch(success(response, serviceId))
      },
      error => {
        dispatch(failure(error))
      }
    )
  }

  function request() {
    return { type: GET_SERVICE_REQUEST }
  }
  function success(response, serviceId) {
    return { type: GET_SERVICE_SUCCESS, response, serviceId }
  }
  function failure(error) {
    return { type: GET_SERVICE_FAILURE, error }
  }
}
