import httpClient from '../utils/axios'
import {
  handleServiceResponse,
  handleServiceError,
} from '../utils/serviceResponseHelper'

const getDocumentBrandings = async serviceId => {
  return httpClient
    .get(
      `${window.config.DOCUMENT_API_URL}/v1/templatebranding/getall?serviceId=${serviceId}`
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const getDocumentBranding = async (brandingId, serviceId) => {
  return httpClient
    .get(
      `${window.config.DOCUMENT_API_URL}/v1/templatebranding/get/?brandingId=${brandingId}&serviceId=${serviceId}`
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const addDocumentBranding = async newBranding => {
  return httpClient
    .post(
      `${window.config.DOCUMENT_API_URL}/v1/templatebranding/create`,
      newBranding
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const updateDocumentBranding = async updatedBranding => {
  return httpClient
    .put(
      `${window.config.DOCUMENT_API_URL}/v1/templatebranding/update`,
      updatedBranding
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

export default {
  getDocumentBrandings,
  getDocumentBranding,
  addDocumentBranding,
  updateDocumentBranding,
}
