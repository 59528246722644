import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

import AdminCard from './adminCard'

const useStyles = makeStyles({
  formControl: { marginBottom: '20px', width: 200 },
  submitControl: { marginTop: '20px' },
  cardComponent: { marginBottom: '20px' },
})

const EditableRegionFormMerge = ({
  mergeFields,
  mergeFieldId,
  mergeFieldIdChanged,
}) => {
  const classes = useStyles()

  return (
    <AdminCard title={'Merge'}>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel>Merge Field</InputLabel>
          <Select
            value={mergeFieldId}
            onChange={e => mergeFieldIdChanged(e.target.value)}>
            <MenuItem value={0}>None</MenuItem>
            {mergeFields.map(item => (
              <MenuItem value={item.id}>{item.value}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </AdminCard>
  )
}

export default EditableRegionFormMerge
