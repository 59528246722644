export const stringCompare = (a, b, direction) => {
  if (direction === 'desc') {
    if (b.toLowerCase() < a.toLowerCase()) return -1
    if (b.toLowerCase() > a.toLowerCase()) return 1
    return 0
  }
  if (a.toLowerCase() < b.toLowerCase()) return -1
  if (a.toLowerCase() > b.toLowerCase()) return 1
  return 0
}
