import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import Wizard from './pages/wizard'
import Admin from './pages/admin'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import ProtectedRoute from './components/routing/protectedRoute'
import AuthenticationProvider from './providers/authenticationProvider'
import userService, { signOutRedirect } from './services/auth.service'
import store from './store'
import roleConstants from './constants/roles.constants'
import NotificationHandler from './components/notificationHandler'
import SignInCallback from './callbacks/signInCallback'
import SignOutCallback from './callbacks/signOutCallback'
import SignInSilentCallback from './callbacks/signInSilentCallback'
import NoPermissions from './pages/noPermissions'
import NoAccess from './pages/noAccess'
import Oops from './pages/oops'
import history from './history'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { ServiceProvider } from './contexts/serviceContext'

function App() {
  const { theme, defaultTheme } = useSelector(state => state.branding)
  const muiTheme = createMuiTheme(theme || defaultTheme)

  const Home = () => (
    <span>
      i am home page <button onClick={signOutRedirect}>sign out</button>
    </span>
  )

  return (
    <AuthenticationProvider userService={userService} store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider>
          <NotificationHandler />
          <div className='App'>
            <MuiThemeProvider theme={muiTheme}>
              <Router history={history}>
                <ServiceProvider>
                  <Switch>
                    <ProtectedRoute exact path='/' component={Home} />
                    <Route path='/signin-oidc' component={SignInCallback} />
                    <Route path='/signout-oidc' component={SignOutCallback} />
                    <Route
                      path='/silent-oidc'
                      component={SignInSilentCallback}
                    />
                    <ProtectedRoute path='/wizard/new' component={Wizard} />
                    <ProtectedRoute
                      path='/wizard/continue'
                      component={Wizard}
                    />
                    <ProtectedRoute
                      requiredRoles={[roleConstants.ADMIN]}
                      path='/admin'
                      component={Admin}
                    />
                    <Route path='/permissions' component={NoPermissions} />
                    <Route path='/access' component={NoAccess} />
                    <Route path='*' component={Oops} />
                  </Switch>
                </ServiceProvider>
              </Router>
            </MuiThemeProvider>
          </div>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </AuthenticationProvider>
  )
}

export default App
