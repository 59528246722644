import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import {
  getEditableRegionGroupInputTypeDescription,
  getEditableRegionGroups,
} from '../../../services/editableregion.service'

import AdminPageHeader from '../../../components/adminPageHeader'
import AdminCard from '../../../components/adminCard'
import AdminCardHeader from '../../../components/adminCardHeader'
import AdminBackdrop from '../../../components/adminBackdrop'
import SortButton from '../../../components/sortButton'
import { useService } from '../../../contexts/serviceContext'
import { stringCompare } from '../../../utils/sortHelper'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: '20px',
    width: 200,
  },
  submitControl: {
    marginTop: '20px',
  },
  arrowIcon: {
    color: theme.palette.primary.main,
  },
}))

const EditableRegionGroupList = () => {
  const classes = useStyles()
  const { serviceId } = useService()

  const [isLoadingList, setIsLoadingList] = useState(false)
  const [editableRegionGroups, setEditableRegionGroups] = useState([])

  const [filterInputTypeId, setfilterInputTypeId] = useState(0)
  const [filterText, setFilterText] = useState('')
  const [filteredAndSorted, setFilteredAndSorted] = useState([])
  const [filteredAndSortedArchived, setFilteredAndSortedArchived] = useState([])
  const [includeArchived, setIncludeArchived] = useState(false)

  useEffect(() => {
    setIsLoadingList(true)
    getEditableRegionGroups(serviceId).then(result => {
      setIsLoadingList(false)
      setEditableRegionGroups(result)
    })
  }, [serviceId])

  const [direction, setDirection] = useState('asc')

  useEffect(() => {
    var filteredAndSorted = editableRegionGroups
      .filter(
        erg =>
          (erg.inputTypeId === filterInputTypeId || filterInputTypeId === 0) &&
          (erg.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
            filterText === '')
      )
      .sort((a, b) => stringCompare(a.name, b.name, direction))
    setFilteredAndSorted(filteredAndSorted.filter(er => !er.isArchived))
    setFilteredAndSortedArchived(filteredAndSorted.filter(er => er.isArchived))
  }, [editableRegionGroups, filterInputTypeId, filterText, direction])
console.log(filteredAndSorted)
  return (
    <>
      <AdminBackdrop show={isLoadingList} />

      {!isLoadingList ? (
        <>
          <AdminPageHeader
            title={'Editable Groups'}
            navActionLabel='Create new'
            navActionPath='/admin/editable/add'
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AdminCard>
                <AdminCardHeader title={'Filter'} />
                <div style={{ marginBottom: 20 }}>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginRight: 10 }}>
                    <InputLabel>Input Type</InputLabel>
                    <Select
                      value={filterInputTypeId}
                      onChange={e => setfilterInputTypeId(e.target.value)}>
                      <MenuItem value={0}>None</MenuItem>
                      <MenuItem value={1}>Address</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginRight: 10 }}>
                    <TextField
                      type='text'
                      label='Name'
                      value={filterText}
                      onChange={e => setFilterText(e.target.value)}
                    />
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginTop: 10 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includeArchived}
                          onChange={e => setIncludeArchived(e.target.checked)}
                          color='primary'
                        />
                      }
                      label='Include Archived?'
                    />
                  </FormControl>
                </div>
                <AdminCardHeader title={'Existing Editable Groups'} />
                {
                  filteredAndSorted && filteredAndSorted.length > 0 &&
                  <SortButton
                    direction={direction}
                    onDirectionChange={setDirection} />
                }
                {!isLoadingList && includeArchived ? <h3>Current:</h3> : null}
                {!isLoadingList ? (
                  filteredAndSorted.length > 0 ? (
                    <List dense>
                      {filteredAndSorted.map(erg => (
                        <ListItem
                          key={erg.id}
                          button
                          component={Link}
                          to={`/admin/editablegroup/${erg.id}`}>
                          <ListItemText
                            primary={erg.name}
                            secondary={getEditableRegionGroupInputTypeDescription(
                              erg.inputTypeId
                            )}
                          />
                          <ArrowForwardIcon className={classes.arrowIcon} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                      <p>No editable regions</p>
                    )
                ) : null}
                {!isLoadingList && includeArchived ? <h3>Archived:</h3> : null}
                {!isLoadingList && includeArchived ? (
                  filteredAndSortedArchived.length > 0 ? (
                    <List dense>
                      {filteredAndSortedArchived.map(erg => (
                        <ListItem
                          key={erg.id}
                          button
                          component={Link}
                          to={`/admin/editable/${erg.id}`}>
                          <ListItemText
                            primary={erg.name}
                            secondary={getEditableRegionGroupInputTypeDescription(
                              erg.inputTypeId
                            )}
                          />
                          <ArrowForwardIcon className={classes.arrowIcon} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                      <p>No archived editable regions</p>
                    )
                ) : null}
              </AdminCard>
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  )
}

export default EditableRegionGroupList
