import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './App'
import './index.scss'
import { NavigationProvider } from './contexts/navigationContext'
import { Provider } from 'react-redux'
import store from './store'
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

 Sentry.init({
     dsn:
         'https://6b6b1f768ec5460c8964a6402254fbdf@o511220.ingest.sentry.io/6299791',
     integrations: [new BrowserTracing()],
     environment: window.config.ENV,
    
     debug:
         window.config.SENTRY_DEBUG !== undefined
             ? window.config.SENTRY_DEBUG
             : true,

     sampleRate:
         window.config.SENTRY_SAMPLERATE !== undefined
             ? window.config.SENTRY_SAMPLERATE
             : 1.0,

     tracesSampleRate:
         window.config.SENTRY_TRACESSAMPLERATE !== undefined
             ? window.config.SENTRY_TRACESSAMPLERATE
             : 1.0,
 })

ReactDOM.render(
    <Provider store={store}>
        <NavigationProvider>
            <App />
        </NavigationProvider>
    </Provider>,
    document.getElementById('root')
)
