import React from 'react'
import { Button } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

const SortButton = ({ direction, onDirectionChange }) => {
  return direction === 'desc' ? (
    <Button onClick={() => onDirectionChange('asc')}>
      Desc
      <ArrowDownwardIcon />
    </Button>
  ) : (
    <Button onClick={() => onDirectionChange('desc')}>
      Asc
      <ArrowUpwardIcon />
    </Button>
  )
}

export default SortButton
