import React, { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { enqueueSnackbar } from '../actions/notification.actions'
import configService from '../services/config.service'

const serviceContext = createContext()
const { Provider } = serviceContext

const defaultServiceConfig = {
  fontSize: '12px',
  fontFamily: 'helvetica',
}

const ServiceProvider = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  // const [serviceId, setServiceId] = useState(0)
  const [currentServiceId, setCurrentServiceId] = useState(0)
  const [serviceConfig, setServiceConfig] = useState(defaultServiceConfig)

  const setServiceId = serviceId => {
    if (serviceId !== currentServiceId && location.pathname.includes('admin')) {
      history.push('/admin')
      dispatch(
        enqueueSnackbar({
          message: 'Service changed to serviceID: ' + serviceId,
          options: { variant: 'info' },
        })
      )
    }

    setCurrentServiceId(serviceId)
  }

  useEffect(() => {
    if (!currentServiceId) return
    configService.getServiceConfig(currentServiceId).then(config => {
      setServiceConfig(config)
    })
  }, [currentServiceId, setServiceConfig])

  const contextValue = {
    serviceId: currentServiceId,
    setServiceId,
    serviceConfig,
  }

  return <Provider value={contextValue}>{children}</Provider>
}

const useService = () => useContext(serviceContext)

export { useService, ServiceProvider }
