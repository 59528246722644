import httpClient from "../utils/axios"

import {
    handleServiceResponse,
    handleServiceError,
} from '../utils/serviceResponseHelper'

const getReviewTypes = async () => {
    return httpClient.get(`${window.config.DOCUMENT_API_URL}/v1/reviewType`)
        .then(response => handleServiceResponse(response),
            error => handleServiceError(error))
}

export default {
    getReviewTypes
}