import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import AdminPageHeader from '../../../components/adminPageHeader'
import AdminCard from '../../../components/adminCard'
import AdminCardHeader from '../../../components/adminCardHeader'
import AdminBackdrop from '../../../components/adminBackdrop'

import Grid from '@material-ui/core/Grid'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SortButton from '../../../components/sortButton'

import { makeStyles } from '@material-ui/core/styles'
import templateService from '../../../services/documenttemplate.service'
import { useService } from '../../../contexts/serviceContext'
import { stringCompare } from '../../../utils/sortHelper'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TableContainer
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: '20px',
    width: 200,
  },
  submitControl: {
    marginTop: '20px',
  },
  arrowIcon: {
    color: theme.palette.primary.main,
  },
}))

const DocumentTemplateList = () => {
  const classes = useStyles()
  const { serviceId } = useService()

  const [isLoadingList, setIsLoadingList] = useState(false)
  const [documentTemplates, setDocumentTemplates] = useState([])

  const [filterText, setFilterText] = useState('')
  const [filteredAndSorted, setFilteredAndSorted] = useState([])
  const [filteredAndSortedArchived, setFilteredAndSortedArchived] = useState([])
  const [includeArchived, setIncludeArchived] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (!serviceId) return

    setIsLoadingList(true)
    templateService.getDocumentTemplates(serviceId).then(res => {
      setIsLoadingList(false)
      setDocumentTemplates(res)
    })
  }, [serviceId])

  const [direction, setDirection] = useState('asc')

  useEffect(() => {
    var filteredAndSorted = documentTemplates
      .filter(
        db =>
          db.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
          filterText === ''
      )
      .sort((a, b) => stringCompare(a.name, b.name, direction))
    setFilteredAndSorted(filteredAndSorted.filter(db => !db.isArchived))
    setFilteredAndSortedArchived(filteredAndSorted.filter(db => db.isArchived))
  }, [documentTemplates, filterText, direction])

  return (
    <>
      <AdminBackdrop show={isLoadingList} />
      {!isLoadingList ? (
        <>
          <AdminPageHeader
            title={'Document Templates'}
            navActionLabel='Create New'
            navActionPath='/admin/template/add'
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AdminCard>
                <AdminCardHeader title={'Filter'} />
                <div style={{ marginBottom: 20 }}>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginRight: 10 }}>
                    <TextField
                      type='text'
                      label='Name'
                      value={filterText}
                      color='primary'
                      onChange={e => setFilterText(e.target.value)}
                    />
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginTop: 10 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includeArchived}
                          onChange={e => setIncludeArchived(e.target.checked)}
                          color='primary'
                        />
                      }
                      label='Include Archived?'
                    />
                  </FormControl>
                </div>
                <AdminCardHeader title={'Existing Templates'} />


                <TableContainer>
                  <Table
                    className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>
                          {
                            filteredAndSorted && filteredAndSorted.length > 0 &&
                            <SortButton
                              direction={direction}
                              onDirectionChange={setDirection} />
                          }
                        </TableCell>
                        <TableCell colSpan={2}>
                          Anytime Doc
                        </TableCell>
                        <TableCell colSpan={2}>
                          Visible To Business Users
                        </TableCell>
                        <TableCell colSpan={2}>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {!isLoadingList && includeArchived ? <h3>Current:</h3> : null}
                      {!isLoadingList ? (
                        filteredAndSorted.length > 0 ? (
                          filteredAndSorted.map(dt => (
                            <TableRow key={dt.id}>
                              <TableCell colSpan={2}>{dt.name}</TableCell>
                              <TableCell colSpan={2}>{dt.latestDocumentTemplateVersion && dt.latestDocumentTemplateVersion.isAnytime ? 'Yes' : 'No'}</TableCell>
                              <TableCell colSpan={2}>{dt.latestDocumentTemplateVersion && dt.latestDocumentTemplateVersion.isVisibleToProcessEngineBusinessUser ? 'Yes' : 'No'}</TableCell>
                              <TableCell colSpan={2}>
                                <IconButton
                                  onClick={() => history.push({ pathname: `/admin/template/${dt.id}` })}>
                                  <ArrowForwardIcon className={classes.arrowIcon} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                            <p>No templates</p>
                          )
                      ) : null}

                      {!isLoadingList && includeArchived ? <h3>Archived:</h3> : null}
                      {!isLoadingList && includeArchived ? (
                        filteredAndSortedArchived.length > 0 ? (
                          filteredAndSortedArchived.map(dt => (
                            <TableRow key={dt.id}>
                              <TableCell>{dt.name}</TableCell>
                              <TableCell>{dt.latestDocumentTemplateVersion && dt.latestDocumentTemplateVersion.isAnytime ? 'Yes' : 'No'}</TableCell>
                              <TableCell>{dt.latestDocumentTemplateVersion && dt.latestDocumentTemplateVersion.isVisibleToProcessEngineBusinessUser ? 'Yes' : 'No'}</TableCell>
                              <TableCell >
                                <IconButton
                                  onClick={() => history.push({ pathname: `/admin/template/${dt.id}` })}>
                                  <ArrowForwardIcon className={classes.arrowIcon} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))

                        ) : (
                            <p>No archived templates</p>
                          )
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>

              </AdminCard>
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  )
}

export default DocumentTemplateList
