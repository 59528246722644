import React, { useEffect, useRef } from 'react'
import { signOutRedirect } from '../services/auth.service'
import { storeUser, userExpiring, userExpired } from '../actions/auth.actions'

const AuthenticationProvider = ({ userService: manager, store, children }) => {
  let userManager = useRef()
  const debugLog = message => {
    if (process.env.NODE_ENV !== 'production') {
      console.debug(message)
    }
  }

  useEffect(() => {
    userManager.current = manager

    const onUserLoaded = user => {
      debugLog('user loaded event')
      store.dispatch(storeUser(user))
    }

    const onUserUnloaded = () => {
      debugLog('unloaded user event')
    }

    const onAccessTokenExpiring = () => {
      debugLog('token expiring event')
      store.dispatch(userExpiring())
      userManager.current.signinSilent()
    }

    const onAccessTokenExpired = () => {
      debugLog('token expired event')
      store.dispatch(userExpired())
      userManager.current.signoutRedirect()
    }

    const onSilentRenewError = () => {
      debugLog('silent renew error event')
    }

    const onUserSignedOut = () => {
      debugLog(`user signed out event`)
    }

    // Raised when a user session has been established (or re-established).
    userManager.current.events.addUserLoaded(onUserLoaded)
    // Raised when a user session has been terminated.
    userManager.current.events.addUserUnloaded(onUserUnloaded)
    // Raised prior to the access token expiring.
    userManager.current.events.addAccessTokenExpiring(onAccessTokenExpiring)
    // Raised after the access token has expired.
    userManager.current.events.addAccessTokenExpired(onAccessTokenExpired)
    // Raised when the automatic silent renew has failed.
    userManager.current.events.addSilentRenewError(onSilentRenewError)
    // Raised when the user's sign-in status at the OP has changed.
    userManager.current.events.addUserSignedOut(onUserSignedOut)

    return () => {
      userManager.current.events.removeUserLoaded(onUserLoaded)
      userManager.current.events.removeUserUnloaded(onUserUnloaded)
      userManager.current.events.removeAccessTokenExpiring(
        onAccessTokenExpiring
      )
      userManager.current.events.removeAccessTokenExpired(onAccessTokenExpired)
      userManager.current.events.removeSilentRenewError(onSilentRenewError)
      userManager.current.events.removeUserSignedOut(onUserSignedOut)
    }
  }, [manager, store])

  return React.Children.only(children)
}

export default AuthenticationProvider
