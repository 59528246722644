import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Container } from '@material-ui/core'
import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useNavigation } from '../contexts/navigationContext'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useLocation } from 'react-router'

const useStyles = makeStyles(theme => {
  return {
    navWrapper: {
      position: 'sticky',
      top: 0,
      width: '100%',
      zIndex: 999,
      backgroundColor: 'white',
    },
    mainNav: {
      backgroundColor: theme.palette.header.main,
      color: theme.palette.getContrastText(theme.palette.header.main),
      height: '128px',
      paddingBottom: '16px',
      paddingTop: '16px',
      transition: 'height 0.3s ease-out',
      display: 'flex',
    },
    subNav: {
      backgroundColor: theme.palette.navigation.main,
      color: theme.palette.getContrastText(theme.palette.navigation.main),
      minHeight: '48px',
      display: 'flex',
      alignItems: 'center',
    },
    subNavLinksContainer: {
      margin: 0,
      padding: 0,
    },
    subNavLeft: {
      marginRight: 'auto',
      '& button': {
        marginLeft: 0,
        marginRight: '32px',
      },
    },
    subNavRight: {
      marginLeft: 'auto',
      '& button': {
        marginRight: 0,
        marginLeft: '32px',
      },
    },
    progressBarColor: {
      backgroundColor: '#a7cbfa',
      marginLeft: '32px',
    },
    barColorPrimary: {
      backgroundColor: '#1475f5',
      height: '8px',
    },
    barHeight: {
      height: '8px',
    },
    greeting: {
      margin: 0,
      marginLeft: 'auto',
      fontWeight: 300,
      '& span': {
        fontWeight: 'bold',
      },
    },
    logo: {
      maxHeight: '100%',
      transition: '0.3s ease-out',
      maxWidth: 400,
      maxHeight: 125,
    },
  }
})

function LinearProgressWithLabel(props) {
  return (
    <Box height='48px' display='flex' alignItems='center'>
      <Box width='95%' mr={1}>
        <LinearProgress {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

function NavBar({
  leftSubNav: LeftSubNav,
  rightSubNav: RightSubNav,
  progressPercentage,
  onBackClick = null,
}) {
  const classes = useStyles()
  const { returnUrl, returnToUrl } = useNavigation()
  const location = useLocation()

  const { brandThemeConfig, applicationName } = useSelector(state => {
    return {
      brandThemeConfig: state.branding.theme || state.branding.defaultTheme,
      applicationName: state.service ? state.service.title : '',
    }
  })

  const isInAdminArea = location.pathname.includes('admin')

  // const handlePageScroll = event => {
  //   const { scrollTop } = event.target.scrollingElement
  //   if (scrollTop > 128) {
  //     document.getElementById('main-nav').style.height = '48px'
  //     document.getElementById('ap-logo').style.visibility = 'hidden'
  //     document.getElementById('ap-logo').style.opacity = 0
  //   } else if (scrollTop < 48) {
  //     document.getElementById('main-nav').style.height = '128px'
  //     document.getElementById('ap-logo').style.visibility = 'visible'
  //     document.getElementById('ap-logo').style.opacity = 1
  //   }
  // }

  // useEffect(() => {
  //   document.addEventListener('scroll', handlePageScroll)
  //   return () => {
  //     document.removeEventListener('scroll', handlePageScroll)
  //   }
  // }, [])

  const goBack = () => {
    // if (typeof onBackClick === 'function') {
    //   onBackClick()
    //   return
    // }

    /**
     * note: this is NOT IDEAL but it gets the job done while the bug around
     * adding returnUrl to /admin is investigated - AP
     */
    if (isInAdminArea) {
      window.location.href = `${window.config.DASHBOARD_URL}/admin/docservice`
      return
    }

    returnToUrl()
  }

  return (
    <header id='back-to-top-anchor' className={classes.navWrapper}>
      <div id='main-nav' className={classes.mainNav}>
        <Container maxWidth='lg' style={{ display: 'flex' }}>
          <Box
            p={1}
            flexShrink={0}
            display='flex'
            alignItems='center'
            justifyContent='center'>
            <img
              id={'ap-logo'}
              src={brandThemeConfig.logoUrl}
              className={classes.logo}
              alt={'logo'}
            />
          </Box>

          <p className={classes.greeting}>
            Welcome to <span>{applicationName}</span>
          </p>
        </Container>
      </div>
      <div className={classes.subNav}>
        <Container maxWidth='lg' style={{ display: 'flex' }}>
          <div
            className={`${classes.subNavLinksContainer} ${classes.subNavLeft}`}>
            {(isInAdminArea || returnUrl) && (
              <Button
                variant='outlined'
                color='secondary'
                onClick={goBack}
                // className={classes.button}
                startIcon={<ArrowBackIcon />}>
                Back
              </Button>
            )}
            {!!LeftSubNav && <LeftSubNav />}
          </div>
          <div
            className={`${classes.subNavLinksContainer} ${classes.subNavRight}`}>
            {!!RightSubNav && <RightSubNav />}
          </div>
        </Container>
      </div>
      {!!progressPercentage && (
        <LinearProgressWithLabel
          classes={{
            barColorPrimary: classes.barColorPrimary,
            root: classes.barHeight,
          }}
          className={`${classes.progressBarColor}`}
          variant='determinate'
          value={progressPercentage}
        />
      )}
    </header>
  )
}

NavBar.propTypes = {
  leftSubNav: PropTypes.func,
  rightSubNav: PropTypes.func,
  progressPercentage: PropTypes.number,
}

export default NavBar
