import { UserManager, Log, WebStorageStateStore } from 'oidc-client'
import httpClient from '../utils/axios'
import { handleServiceError } from '../utils/serviceResponseHelper'
import appServiceConstants from '../constants/appservice.constants'
import { storeUser } from '../actions/auth.actions'
import store from '../store'

const config = {
  authority: window.config.IDENTITY_URL,
  client_id: window.config.OIDC_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''
    }/signin-oidc`,
  response_type: 'id_token token',
  scope:
    'openid profile processenginedocumentserviceapiaccess processengineserviceapiaccess configurationapiaccess processengineuserapiaccess caseapiaccess',
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}/signout-oidc`,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}/silent-oidc`,
  loadUserInfo: true,
  includeIdTokenInSilentRenew: false,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
}

const userManager = new UserManager(config)

if (process.env.NODE_ENV !== 'production') Log.logger = console

export async function getAccessToken() {
  try {
    const user = await userManager.getUser()
    if (!user || user.expired) {
      return null
    }
    return `Bearer ${user.access_token}`
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function checkServiceAccess() {
  return httpClient.get(`${window.config.CONFIG_API_URL}/api/allowedapps`).then(
    response => {
      const { data: allowedAppsList } = response
      return allowedAppsList.some(
        app => app.id === appServiceConstants.DOC_SERVICE_APP || app.Id === appServiceConstants.DOC_SERVICE_APP
      )
    },
    error => handleServiceError(error)
  )
}

export async function getUser() {
  try {
    const user = await userManager.getUser()
    if (!user || user.expired) {
      return null
    }
    store.dispatch(storeUser(user))
    return user
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function signInRedirect(state) {
  return userManager.signinRedirect(state)
}

export function signInRedirectCallback() {
  return userManager.signinRedirectCallback()
}

export function signInSilent() {
  return userManager.signinSilent()
}

export function signInSilentCallback() {
  return userManager.signinSilentCallback()
}

export function signOutRedirect() {
  return userManager.signoutRedirect()
}

export async function signOutRedirectCallback() {
  await userManager.clearStaleState()
  await userManager.removeUser()
  return userManager.signoutRedirectCallback()
}

export default userManager
