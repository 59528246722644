import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/Delete'
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'

import AdminCard from './adminCard'

const useStyles = makeStyles({
  formControl: { marginBottom: '20px', width: 200 },
  submitControl: { marginTop: '20px' },
  cardComponent: { marginBottom: '20px' },
})

const EditableRegionFormOptions = ({ id, options, optionsChanged }) => {
  const classes = useStyles()

  const [newOption, setNewOption] = useState('')
  const [newOptionIsValid, setNewOptionIsValid] = useState(false)
  const [newOptionValidationMessage, setNewOptionValidationMessage] = useState(
    ''
  )

  const minOptionLength = 3
  const maxOptionLength = 280

  useEffect(() => {
    if (newOption.length < minOptionLength) {
      setNewOptionIsValid(false)
      setNewOptionValidationMessage(
        `Enter at least ${minOptionLength} characters`
      )
    } else if (newOption.length > maxOptionLength) {
      setNewOptionIsValid(false)
      setNewOptionValidationMessage(
        `Enter ${maxOptionLength} characters or fewer`
      )
    } else if (options.find(x => x.value === newOption)) {
      setNewOptionIsValid(false)
      setNewOptionValidationMessage('Option already exists')
    } else {
      setNewOptionIsValid(true)
      setNewOptionValidationMessage('Valid Input')
    }
  }, [newOption])

  const [optionsAreValid, setOptionsAreValid] = useState(false)

  useEffect(() => {
    setOptionsAreValid(
      options.filter(x => !x.isArchived).length > 1 ? true : false
    )
  }, [options])

  const [optionsValidationMessage, setOptionsValidationMessage] = useState('')

  useEffect(() => {
    setOptionsValidationMessage(
      optionsAreValid ? 'Valid Entry' : 'Requires at least 2 options'
    )
  }, [optionsAreValid])

  const addOption = () => {
    optionsChanged([
      ...options,
      {
        id: 0,
        editableRegionId: id,
        value: newOption,
        description: null,
        isArchived: false,
      },
    ])
    setNewOption('')
  }

  const deleteOption = index => {
    options[index].isArchived = !options[index].isArchived
    optionsChanged([...options])
  }

  return (
    <AdminCard title={'Options'}>
      <div>
        {options.length === 0 ? (
          <p>No options</p>
        ) : (
          <List dense>
            {options
              .sort((a, b) => a.isArchived > b.isArchived)
              .map((option, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={option.value}
                    style={
                      option.isArchived
                        ? { textDecoration: 'line-through' }
                        : {}
                    }
                  />
                  <ListItemSecondaryAction onClick={() => deleteOption(index)}>
                    <IconButton edge='end' aria-label='delete'>
                      {option.isArchived ? (
                        <RestoreFromTrashIcon />
                      ) : (
                        <DeleteIcon />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        )}
      </div>
      <div>
        <FormControl className={classes.formControl} error={!newOptionIsValid}>
          <TextField
            type='text'
            label='Name'
            value={newOption}
            onChange={e => setNewOption(e.target.value)}
          />
          <FormHelperText>{newOptionValidationMessage}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl className={classes.formControl} error={!optionsAreValid}>
          <FormHelperText>{optionsValidationMessage}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <Button
          className={classes.submitControl}
          disabled={!newOptionIsValid}
          onClick={addOption}
          variant='contained'
          color='secondary'>
          Add
        </Button>
      </div>
    </AdminCard>
  )
}

export default EditableRegionFormOptions
