import React, { useState, useContext, createContext, useEffect } from 'react'
import qs from 'query-string'

const RETURN_URL_KEY = 'ds:returnUrl'

const NavigationContext = createContext()
const { Provider } = NavigationContext

const NavigationProvider = ({ children }) => {
  const [returnUrl, setReturnUrl] = useState('')

  useEffect(() => {
    const parsedUrl = qs.parseUrl(window.location.href)

    // pull out query and base url
    const { query } = parsedUrl
    // set context properties - can add more here as we go
    saveReturnUrl(query.returnUrl)
  }, [])

  const saveReturnUrl = returnUrl => {
    // check local storage if we dont have a return url
    if (!returnUrl) {
      const urlFromStorage = localStorage.getItem(RETURN_URL_KEY)
      if (!urlFromStorage) return

      setReturnUrl(urlFromStorage)
      return
    }

    const decodedReturnUrl = decodeURIComponent(returnUrl)
    // set return url in context
    setReturnUrl(decodedReturnUrl)

    // set return url in local storage for refresh persistence
    localStorage.setItem(RETURN_URL_KEY, decodedReturnUrl)
  }

  const returnToUrl = () => {
    if (!returnUrl) return

    window.location.href = returnUrl
  }

  const contextValue = {
    returnUrl,
    returnToUrl,
  }

  return <Provider value={contextValue}>{children}</Provider>
}

const useNavigation = () => useContext(NavigationContext)

export { useNavigation, NavigationProvider }
